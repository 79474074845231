(function () {
    'use strict';
    angular
        .module('alpha.common.alphaSmartTable')
        .factory('AlphaSmartTableService', AlphaSmartTableService);

    AlphaSmartTableService.$inject = [
        'I18nUtil',
        'Utils',
        'RecordDataUtil',
        'SearchInterface',
        '$q',
        'UserPreferences',
        'AGGREGATE_DATA_TYPES',
        '$http',
        '$locale',
        'DataModelDesignInterface',
    ];

    function AlphaSmartTableService(
        I18nUtil,
        Utils,
        RecordDataUtil,
        SearchInterface,
        $q,
        UserPreferences,
        AGGREGATE_DATA_TYPES,
        $http,
        $locale,
        DataModelDesignInterface
    ) {
        var _resultsLimit = 5000;
        var systemFieldsAllowedForChart = ['_entity_', '_entityDescription'];
        var masterSearchFilter = {};
        var masterFilterApplied = false;
        var currentMasterFilters = {};
        var masterFiltersApplied = {};
        var masterFilters = {};
        var _defaultCurrency = {};
        var gridInEditMode = false;
        var _showEmailOption = false;

        return {
            customSearchFilter : customSearchFilter,
            filterCollectionBySearchText: filterCollectionBySearchText,
            isPieChart: isPieChart,
            isBarOrLineChart: isBarOrLineChart,
            isChartColumn: isChartColumn,
            getNumberTypeColumns: getNumberTypeColumns,
            getNumberAndDateTypeColumns: getNumberAndDateTypeColumns,
            isChartColumnSelected: isChartColumnSelected,
            isValidColumnSelection: isValidColumnSelection,
            isXAxisChartColumnSelected: isXAxisChartColumnSelected,
            isYAxisChartColumnSelected: isYAxisChartColumnSelected,
            getSelectedColumns: getSelectedColumns,
            getXAxisSelectedColumns: getXAxisSelectedColumns,
            getYAxisSelectedColumns: getYAxisSelectedColumns,
            getMappedFieldForMeasureColumns: getMappedFieldForMeasureColumns,
            getFieldForMeasureColumn: getFieldForMeasureColumn,
            isFieldForMeasureVisible: isFieldForMeasureVisible,
            getMeasureOptions: getMeasureOptions,
            getMappedSeriesColumns: getMappedSeriesColumns,
            getSeriesColumn: getSeriesColumn,
            getFormattedChartData: getFormattedChartData,
            isColumnRightAligned: isColumnRightAligned,
            getResultsLimit: getResultsLimit,
            getNumberOfPages: getNumberOfPages,
            getNumberOfResults: getNumberOfResults,
            performSearchForDownload: performSearchForDownload,
            getCsvSeparatorValue: getCsvSeparatorValue,
            setMasterSearchFilter: setMasterSearchFilter,
            getMasterSearchFilter: getMasterSearchFilter,
            getSelectedAssociatedNodeFromTree: getSelectedAssociatedNodeFromTree,
            getSelectedAssociatedRecordTypeIdFromTree: getSelectedAssociatedRecordTypeIdFromTree,
            getPrimaryRecordTypeIdFromTree: getPrimaryRecordTypeIdFromTree,
            getRecordTypeIdsFromTerms: getRecordTypeIdsFromTerms,
            getRecordTypeIdForSelectedFieldFromFieldList: getRecordTypeIdForSelectedFieldFromFieldList,
            getRecordTypeIdsFromFieldList: getRecordTypeIdsFromFieldList,
            getRecordTypeIdsFromTree: getRecordTypeIdsFromTree,
            getRecordTypeIdsFromQuery: getRecordTypeIdsFromQuery,
            getRecordTypesForQuery: getRecordTypesForQuery,
            getSavedSearch: getSavedSearch,
            getRecordType: getRecordType,
            mergeFilterTerms: mergeFilterTerms,
            setMasterFilterApplied: setMasterFilterApplied,
            isMasterFilterApplied: isMasterFilterApplied,
            setCurrentMasterFilter: setCurrentMasterFilter,
            getCurrentMasterFilter: getCurrentMasterFilter,
            setMasterFilter: setMasterFilter,
            getMasterFilter: getMasterFilter,
            resetMasterFilter: resetMasterFilter,
            fetchDefaultCurrency: fetchDefaultCurrency,
            setDefaultCurrency: setDefaultCurrency,
            isGridInEditMode: isGridInEditMode,
            setGridInEditMode: setGridInEditMode,
            canShowEmailOption : canShowEmailOption,
            setShowEmailOption : setShowEmailOption
        };

        /**
         * Custom search filter based on display data
         * @param columns - Table column configuration
         * @returns {Function}
         */
        function customSearchFilter(columns){
            return function (data, predicate){
                var text = predicate.$;
                return text ? filterCollectionBySearchText(data, text, columns) : data;
            };
        }
        function filterCollectionBySearchText(data, text, columns){
            return _.filter(data, function(object){
                return customCompare(object, text.toLowerCase(), columns);
            });
            function customCompare(object, predicate, columns){
                var text,
                    match = false;
                _.forEach(columns,function (column) {
                    text = (column.getter) ?  column.getter(object) : object[column.name];
                    if((_.isString(text)  || _.isNumber(text)) && text.toString().toLowerCase().indexOf(predicate) > -1){
                        match = true;
                        return false;
                    }
                });
                return match;
            }
        }
        function isColumnRightAligned(column) {
            if (column.aggregateFunction === 'COUNT' || column.aggregateFunction === 'DISTINCT_COUNT') {
                return RecordDataUtil.isRightAligned('Number');
            } else {
                return column.fieldMetadata && RecordDataUtil.isRightAligned(column.fieldMetadata.dataType);
            }
        }
        function isChartColumn(column){
            return column.fieldMetadata &&
                (
                    _.includes(['Date', 'DateTime', 'Number', 'Currency', 'LookupLibrary'], column.fieldMetadata.dataType) ||
                    _.includes(systemFieldsAllowedForChart, column.fieldTypeId)
                );
        }
        function getNumberTypeColumns(columns){
            return _.chain(columns)
                .filter(function(column) {
                    return column.fieldMetadata && _.includes(['Number', 'Currency'], column.fieldMetadata.dataType);
                })
                .uniqBy(_getColumnFieldTypeId)
                .value();
        }
        function getNumberAndDateTypeColumns(columns){
            return _.chain(columns)
                .filter(isChartColumn)
                .uniqBy(_getColumnFieldTypeId)
                .value();
        }
        function isChartColumnSelected(column){
            return !_.isEmpty(column);
        }
        function isValidColumnSelection(type, columns, seriesColumn, measure, fieldForMeasureColumn){
            if (isPieChart(type)) {
                if (_.isEmpty(measure)) {
                    return false;
                } else if (measure === 'COUNT') {
                    return isChartColumnSelected(seriesColumn);
                } else {
                    return isChartColumnSelected(seriesColumn) && isChartColumnSelected(fieldForMeasureColumn);
                }
            } else {
                return isXAxisChartColumnSelected(columns) && isYAxisChartColumnSelected(columns);
            }
        }
        function isXAxisChartColumnSelected(columns){
            var selectedColumns = getXAxisSelectedColumns(columns);
            return !_.isEmpty(selectedColumns);
        }
        function isYAxisChartColumnSelected(columns){
            var selectedColumns = getYAxisSelectedColumns(columns);
            return !_.isEmpty(selectedColumns);
        }
        function isPieChart(type){
            return type === 'LBL_PIE_CHART' || type === 'PIE';
        }
        function isBarOrLineChart(type){
            return type === 'LBL_LINE_CHART' || type === 'LINE' || type === 'LBL_BAR_CHART' || type === 'BAR';
        }
        function getSelectedColumns(columns){
            return _.filter(columns, 'selected');
        }
        function getXAxisSelectedColumns(columns){
            return _.filter(columns, 'xSelected');
        }
        function getYAxisSelectedColumns(columns){
            return _.filter(columns, 'ySelected');
        }
        function getMappedFieldForMeasureColumns(columns){
            return _.map(getNumberTypeColumns(columns),function(column) {
                return {id: column.fieldTypeId, text: column.displayName};
            });
        }
        function getMappedSeriesColumns(columns){
            return _.map(_getAggregateColumns(columns), function(column) {
                return {id: column.fieldTypeId, text: column.displayName};
            });
        }
        function isFieldForMeasureVisible(type, measure, options){
            return isPieChart(type) && (measure === 'SUM' || measure === 'AVG') && !_.isEmpty(options);
        }
        function getFieldForMeasureColumn(columns, columnName){
            var column = _.find(getNumberTypeColumns(columns), {fieldTypeId: columnName});
            return column ? [column] : [];
        }
        function getSeriesColumn(columns, columnName){
            var column = _.find(columns, {fieldTypeId: columnName});
            return column ? [column] : [];
        }
        function getMeasureOptions(){
            return [{
                id: 'COUNT',
                text: I18nUtil.getI18nString('LBL_COUNT', 'Count')
            },{
                id: 'SUM',
                text: I18nUtil.getI18nString('LBL_SUM', 'Sum')
            },{
                id: 'AVG',
                text: I18nUtil.getI18nString('LBL_AVERAGE', 'Average')
            }];
        }
        function reverseString(str) {
            return str.split("").reverse().join("");
        }
        function performSearchForDownload(query, withChart, downloadType){
            query = angular.extend({}, query,  {format: {date: $locale.DATETIME_FORMATS.shortDate, time:$locale.DATETIME_FORMATS.shortTime} });
            var masterFilterChanges = mergeFilterTerms(query);
            if(!_.isEmpty(masterFilterChanges) && isMasterFilterApplied(query.id | masterFilterChanges.queryName)) {
                query = angular.extend({}, query, masterFilterChanges);
            }

            var deferred = $q.defer(),
                queryForDownload = angular.copy(query);

            queryForDownload = _.omit(queryForDownload, ['limit', 'skip', 'pagingEnabled']);
            queryForDownload.defaultUiLocale =  I18nUtil.getLocale();
            queryForDownload.numberFormatStr = RecordDataUtil.getNumberFormatStr();

            UserPreferences.getClientId()
                .then(function(clientId){
                    if(downloadType === 'xlsx'){
                        _downloadExcel(clientId);
                    }else if(downloadType === 'new_xlsx'){
                        _downloadExcelNew(clientId);
                    } else if(downloadType === 'd2xl'){
                        _downloadExcelReport(clientId);
                    }else if (downloadType === 'new_d2xl'){
                        _downloadExcelReportNewMethod(clientId);
                    }
                    else{
                        _downloadCSV(clientId,downloadType);
                    }
                })

            return deferred.promise;

            function _downloadExcel(clientId){
                $http({
                    method: 'POST',
                    url:'/Alpha/dp/download/' + clientId,
                    responseType: 'blob',
                    data: query,
                    params:{
                        downloadType: 'xlsx',
                        qfs: 'oracle',
                        withChart: withChart === true
                    }
                })
                    .then(function(response){
                        deferred.resolve(response.data);
                    })
                    .catch(function(response){
                        deferred.reject(response.data);
                    });
            }
            function _downloadExcelNew(clientId){
                $http({
                    method: 'POST',
                    url: applicationContextRoot + '/dp/exel-download/' + clientId + '/ForUpload',
                    responseType: 'blob',
                    data: query,
                    params:{
                        downloadType: 'xlsx',
                        qfs: 'oracle',
                        withChart: withChart === true,
                        downloadExcel: true
                    }
                })
                    .then(function(response){
                        deferred.resolve(response.data);
                    })
                    .catch(function(response){
                            deferred.reject(response.data);
                    });
            }


            function _downloadExcelReport(clientId){
                $http({
                    method: 'POST',
                    url:'/Alpha/dp/download/excel/' + clientId,
                    responseType: 'blob',
                    data: query,
                    params:{
                        downloadType: 'xlsx',
                        qfs: 'oracle',
                        withChart: withChart === true,
                        downloadExcel: true
                    }
                })
                .then(function(response){
                    deferred.resolve(response);
                })
                .catch(function(response){
                    deferred.reject(response.data);
                });
            }
            function _downloadExcelReportNewMethod(clientId){
                $http({
                    method: 'POST',
                    url: applicationContextRoot + '/dp/exel-download/' + clientId + '/Normal',
                    responseType: 'blob',
                    data: query,
                    params:{
                        downloadType: 'xlsx',
                        qfs: 'oracle',
                        withChart: withChart === true,
                        downloadExcel: true
                    }
                })
                    .then(function(response){
                        deferred.resolve(response);
                    })
                    .catch(function(response){
                        deferred.reject(response.data);
                    });
            }

            function _downloadCSV(clientId,type){

                if(type == 'newMethod_csv') {
                    $http({
                        method: 'POST',
                        url: applicationContextRoot + '/dp/csv-download/' + clientId,
                        responseType: 'blob',
                        data: queryForDownload
                    })
                    .then(function(response){
                            deferred.resolve(response.data);
                    },function error(data) {
                        deferred.reject(data);
                    });
                }
                else {
                    SearchInterface.performDownloadFromPost(
                        clientId,
                        queryForDownload,
                        'oracle',
                        withChart === true,
                        downloadType || 'csv',
                        function success(response) {
                            deferred.resolve(response.searchResults);
                        },
                        function error(response) {
                            deferred.reject(response);
                        });

                }
            }
        }

        function getSavedSearch(savedSearchId, fromCache) {
            var deferred = $q.defer();
            UserPreferences.getClientId()
                .then(function(clientId) {
                    if (fromCache) {
                        SearchInterface.getSavedSearchFromCache(
                            clientId,
                            savedSearchId,
                            function success(response) {
                                deferred.resolve(response.searchQuery);
                            },
                            function error(response) {
                                deferred.reject(response.data.errorMessage);
                            }
                        );
                    } else {
                        SearchInterface.getSavedSearch(
                            clientId,
                            savedSearchId,
                            function success(response) {
                                deferred.resolve(response.searchQuery);
                            },
                            function error(response) {
                                deferred.reject(response.data.errorMessage);
                            }
                        );
                    }
                })
                .catch(function(reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        function getRecordTypesForQuery(searchQuery) {
            var promises = {},
                deferred = $q.defer(),
                self = this;
            _.forEach(self.getRecordTypeIdsFromQuery(searchQuery), function(recordTypeId) {
                promises[recordTypeId] = self.getRecordType(recordTypeId);
            });
            $q.all(promises)
                .then(function(responses) {
                    deferred.resolve(responses);
                })
                .catch(function(reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        function getRecordType(recordTypeId) {
            var deferred = $q.defer();
            UserPreferences.getClientId()
                .then(function(clientId) {
                    DataModelDesignInterface.getRecordTypeFromCache(
                        clientId,
                        recordTypeId,
                        function success(response) {
                            deferred.resolve(response.recordType);
                        },
                        function error(response) {
                            deferred.reject(response.data.errorMessage);
                        }
                    );
                })
                .catch(function(reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        function getRecordTypeIdsFromQuery(searchQuery) {
            return _.chain([])
                .concat(this.getRecordTypeIdsFromTree(searchQuery))
                .concat(this.getRecordTypeIdsFromFieldList(searchQuery))
                .concat(this.getRecordTypeIdsFromTerms(searchQuery))
                .uniq()
                .value();
        }
        function getRecordTypeIdsFromTree(searchQuery) {
            if (_.isObject(searchQuery) && _.isObject(searchQuery.recordTypeTree)) {
                return _.chain([this.getPrimaryRecordTypeIdFromTree(searchQuery)])
                    .concat(_getChildRecordTypesFromNode(searchQuery.recordTypeTree))
                    .concat(_getDeeplyAssociatedRecordTypes())
                    .filter(_.isString)
                    .value();
            } else if (_.isObject(searchQuery) && _.isArray(searchQuery.recordTypes)) {
                return searchQuery.recordTypes;
            } else {
                return [];
            }
            function _getDeeplyAssociatedRecordTypes() {
                var recordTypes = [];
                _.forEach(searchQuery.recordTypeTree.children, function(child) {
                    recordTypes = recordTypes.concat(_getChildRecordTypesFromNode(child));
                });
                return recordTypes;
            }
            function _getChildRecordTypesFromNode(node) {
                return _.chain(node)
                    .get('children')
                    .map('id')
                    .filter(_.isString)
                    .value();
            }
        }
        function getRecordTypeIdsFromFieldList(searchQuery) {
            return _.chain(searchQuery)
                .get('fieldList')
                .map('recordTypeId')
                .uniq()
                .value();
        }
        function getRecordTypeIdForSelectedFieldFromFieldList(fieldList, fieldId) {
            var field;
            field = _.find(fieldList, {fieldName: fieldId});
            return field.recordTypeId;
        }
        function getRecordTypeIdsFromTerms(searchQuery) {
            var recordTypeIds = [];
            if (_.isObject(searchQuery)) {
                _addRecordTypeIdsFromTerms(searchQuery.terms);
            }
            function _addRecordTypeIdsFromTerms(terms) {
                _.forEach(terms, function (term) {
                    if (_.isObject(term.field)) {
                        recordTypeIds.push(term.field.recordTypeId);
                        if (term.field.fieldName === '_entity_' && _.isArray(term.value) && _.isString(term.value[0]) && !_.isEmpty(term.value[0])) {
                            recordTypeIds.push(term.value[0]);
                        }
                    }
                    _addRecordTypeIdsFromTerms(term.terms);
                });
            }
            return _.chain(recordTypeIds)
                .filter(_.isString)
                .uniq()
                .value();
        }
        function getPrimaryRecordTypeIdFromTree(searchQuery) {
            if (_.isObject(searchQuery)) {
                if (_.isObject(searchQuery.recordTypeTree)) {
                    return _.get(searchQuery, 'recordTypeTree.id');
                } else if (_.isArray(searchQuery.recordTypes)) {
                    return searchQuery.recordTypes[0];
                }
            }
        }
        function getSelectedAssociatedRecordTypeIdFromTree(searchQuery) {
            return _.get(this.getSelectedAssociatedNodeFromTree(searchQuery), 'id');
        }
        function getSelectedAssociatedNodeFromTree(searchQuery) {
            return _.chain(searchQuery)
                .get('recordTypeTree.children')
                .filter(function(child) {
                    return child.value && ((child.value.associationType && child.value.associationType !== 'ManyToOne') || (child.value.linkedRecordTypeId));
                })
                .first()
                .value();
        }

        /**
         * Converts a collection of aggregated search results into a data set
         * for use with C3 by using a chart configuration from a search query.
         *
         * @method getFormattedChartData
         *
         * @param {Object[]} results Aggregate results from a performed search
         * @param {String} chartType Type of chart being displayed
         * @param {String} aggregateType Aggregate that was applied to the data
         * @param {Object} xColumn Column configuration from the related grid
         * @param {String} xColumn.fieldTypeId Service name of the field type the column refers to
         * @param {Object} xColumn.displayName Display name of the field type the column refers to
         * @param {Object} xColumn.fieldMetadata Metadata of the field type the column refers to
         * @param {Object} yColumn Column configuration from the related grid
         * @param {String} yColumn.fieldTypeId Service name of the field type the column refers to
         * @param {Object} yColumn.displayName Display name of the field type the column refers to
         * @param {Object} yColumn.fieldMetadata Metadata of the field type the column refers to
         *
         * @returns {Object} Converted data set to be used with C3
         */
        function getFormattedChartData(results, chartType, aggregateType, xColumn, yColumn) {
            switch (chartType) {
                case 'PIE':
                    return _getPieData();
                case 'BAR':
                case 'LINE':
                    return _getBarData();
            }
            function _getPieData() {
                var data = {};
                _.forEach(results, function(result) {
                    var key = _getDisplayValue(result, xColumn),
                        val = yColumn ? _getNumericValueForAssoc(result[yColumn.fieldTypeId], yColumn.fieldMetadata.dataType, result, yColumn) : result['_' + aggregateType];
                    data[key] = val;
                });
                return data;
            }
            function _getBarData() {
                var data = {};
                if (aggregateType) {
                    data.x = [];
                    _.forEach(results, function(result) {
                        var yKey = xColumn.displayName,
                            xVal = _getDisplayValue(result, xColumn),
                            yVal = yColumn ? _getNumericValueForAssoc(result[yColumn.fieldTypeId], yColumn.fieldMetadata.dataType, result, yColumn) : null;
                        data.x.push(xVal);
                        data[yKey] = data[yKey] || [];
                        data[yKey].push(yVal);
                    });
                } else {
                    _.forEach(results, function(result) {
                        var ykey = yColumn.displayName,
                            yVal = yColumn ? _getNumericValueForAssoc(result[yColumn.fieldTypeId], yColumn.fieldMetadata.dataType, result, yColumn) : null;
                        data[ykey] = data[ykey] || [];
                        data[ykey].push(yVal);
                    });
                }
                return data;
            }
            function _getDisplayValue(row, column) {
                var fieldValue = column.fieldTypeId === '_entity_' ? row['_entityDescription'] : row[column.fieldTypeId],
                    fieldMetadata = column.fieldMetadata;
                var isRedacted = _.get(fieldMetadata, 'pii_mask') === null;
                if (fieldValue === undefined) {
                    fieldValue = row[column.recordTypeId][column.fieldTypeId];
                }
                if (fieldValue === null) {
                    return I18nUtil.getI18nString('LBL_NO_VALUE', 'No Value');
                } else {
                    return RecordDataUtil.getDisplayValueForField(fieldValue, fieldMetadata,isRedacted);
                }
            }
            function _getNumericValueForAssoc(fieldValue, dataType, row, column) {
                if (fieldValue === null) {
                    return null;
                }
                if (fieldValue === undefined) {
                    fieldValue = row[column.recordTypeId][column.fieldTypeId];
                }
                return _getNumericValue(fieldValue, dataType);
            }
            function _getNumericValue(fieldValue, dataType) {
                if (fieldValue === null) {
                    return null;
                } else {
                    return RecordDataUtil.getNumericValue(fieldValue, dataType);
                }
            }
        }
        function getResultsLimit(){
            return _resultsLimit;

        }
        function getNumberOfPages(totalElements, pageSize, totalPages){
            return totalElements > _resultsLimit ? _.ceil(_resultsLimit / pageSize) : totalPages;
        }
        function getNumberOfResults(totalElements){
            return totalElements > _resultsLimit ? _resultsLimit : totalElements;
        }

        // Private functions

        function _getAggregateColumns(columns){
            return _.chain(columns)
                .filter(function(column) {
                    return column.fieldMetadata && _.includes(AGGREGATE_DATA_TYPES, column.fieldMetadata.dataType);
                })
                .uniqBy(_getColumnFieldTypeId)
                .value();
        }
        function _getColumnFieldTypeId(column) {
            return column.recordTypeId + '.' + column.fieldTypeId;
        }
        function getCsvSeparatorValue(){
            var deferred = $q.defer();
            DataModelDesignInterface.getCsvSeparatorValue(
                function success(response){
                    deferred.resolve(response. symbol);
                 },
                function error(reason){
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        function setMasterSearchFilter( masterFilterTerms, queryName) {
            masterSearchFilter[queryName] = masterFilterTerms;
        }

        function getMasterSearchFilter(queryName) {
            return masterSearchFilter[queryName];
        }

        function setMasterFilterApplied(applyMasterFilter, queryName) {
            masterFiltersApplied[queryName] = applyMasterFilter;
            masterFilterApplied = applyMasterFilter;
            if(applyMasterFilter === false){
                masterSearchFilter[queryName] = {};
            }
        }

        function isMasterFilterApplied(queryName) {
            if(queryName && masterFiltersApplied){
                return masterFiltersApplied[queryName];
            }
            return false;
        }

        function setCurrentMasterFilter(currentFilters) {
            currentMasterFilters = currentFilters;
        }
        function getCurrentMasterFilter() {
            return currentMasterFilters;
        }
        function setMasterFilter(currentFilters, query) {
            masterFilters[query] = currentFilters;
        }
        function getMasterFilter(query) {
            return masterFilters[query];
        }
        function resetMasterFilter() {
            masterFilters = {};
            masterFiltersApplied = {};
        }

        /**
         * This function uses query id provided in the originalQuery. For quick search this would be absent. Therefore for
         * quick search it relies on the second parameter.
         * @param originalQuery the query id used by the quick search.
         * @param queryId
         * @returns {*}
         */
        function mergeFilterTerms(originalQuery, queryId) {
            var originalTerms = [];
            var actualMasterFilterChanges = getMasterSearchFilter(originalQuery.id ? originalQuery.id : queryId);
            var masterFilterChanges = angular.copy(actualMasterFilterChanges);
            if (_.isEmpty(masterFilterChanges) || _.isEmpty(masterFilterChanges.terms)) {
                masterFilterChanges = {};
                masterFilterChanges.termGroupOperator = 'AND';
                if (!_.isEmpty(originalQuery.terms)) {
                    originalTerms.push({
                        operator: angular.copy(originalQuery.termGroupOperator),
                        terms: angular.copy(originalQuery.terms)
                    });
                }
                masterFilterChanges.terms = originalTerms;
            } else if (!_.isEmpty(masterFilterChanges)) {
                masterFilterChanges.termGroupOperator = 'AND';
                _populateAsOfDateAndLkpLevelIntoSearchTerms(originalQuery, masterFilterChanges.terms);
                if (!_.isEmpty(originalQuery.terms)) {
                    originalTerms.push({
                        operator: angular.copy(originalQuery.termGroupOperator),
                        terms: angular.copy(originalQuery.terms)
                    });
                    _populateAsOfDateAndLkpLevelIntoSearchTerms(originalQuery, masterFilterChanges.terms);
                    originalTerms.push({
                        operator: 'AND',
                        terms: masterFilterChanges.terms
                    });
                    masterFilterChanges.terms = originalTerms;
                }
                if (originalQuery.currencyConversionType === 'clientDefaultCurrency') {
                    masterFilterChanges.currency = _defaultCurrency.id;
                }
            }

            function _populateAsOfDateAndLkpLevelIntoSearchTerms(query, userFilterTerms) {
                _.forEach(userFilterTerms, function(userFilterTerm){
                    if(userFilterTerm.field && userFilterTerm.field.fieldName) {
                        _.forEach(query.fieldList, function (tempField) {
                            //Ordinal check is required here when query field list has same field more than one time
                            if (tempField.fieldName === userFilterTerm.field.fieldName && tempField.ordinal === userFilterTerm.field.ordinal) {
                                if (tempField.asOfDate) {
                                    userFilterTerm.field.asOfDate = angular.copy(tempField.asOfDate);
                                }
                                if (tempField.specificLevel) {
                                    userFilterTerm.field.specificLevel = angular.copy(tempField.specificLevel);
                                }
                                return false; //We are breaking the forEach loop
                            }
                        });
                    }
                });
            }

            return masterFilterChanges;
        }

        function setDefaultCurrency(defaultCurrency){
            _defaultCurrency = defaultCurrency;
        }

        function isGridInEditMode(){
            return gridInEditMode;
        }

        function setGridInEditMode(editMode){
            gridInEditMode = editMode;
        }

        function setShowEmailOption(showEmailOption){
            _showEmailOption = showEmailOption;
        }

        function canShowEmailOption(){
            return _showEmailOption;
        }
        function fetchDefaultCurrency(){
            var deferred = $q.defer();
            UserPreferences.getClientId()
                .then(function success(clientId) {
                    DataModelDesignInterface.getDefaultCurrencyFromCache(
                        clientId,
                        function success(data) {
                            setDefaultCurrency(data.currency);
                            deferred.resolve(data);
                        },
                        function error(reason) {
                            deferred.reject(reason);
                        }
                    );
                })
                .catch(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
    }
})();
